<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" title="详情" class="button_bg" width="60%" >
    <el-table :data="tableData" style="width: 100%" border :height="contentStyleObj">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="beginAt" label="开始时间" min-width="150">
        <template #default="scope">
          <span v-if="scope.row.taskStatus != 1 && scope.row.taskStatus != 2 && scope.row.taskStatus != 99">
            {{ scope.row.beginAt }}
          </span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column prop="endAt" label="结束时间" min-width="150" >
        <template #default="scope">
          <span v-if="scope.row.taskStatus != 1 && scope.row.taskStatus != 2">
            {{ scope.row.endAt }}
          </span> 
          <span v-else></span>
        </template>
      </el-table-column>


      <!-- 跟外部保持一致 -->
      <!-- 进项 销项 银行 -->
      <el-table-column prop="districtName" label="记录" min-width="140" v-if="this.taskName == 'tax-cj-inInvoice' || this.taskName == 'tax-cj-outInvoice' || this.taskName == 'cj-bank' || this.taskName == 'tax-year-cj'">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p>
                <el-tooltip :disabled="(scope.row.errLog?scope.row.errLog:'' + scope.row.bussinessLog?scope.row.bussinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.errLog?scope.row.errLog:'' + scope.row.bussinessLog?scope.row.bussinessLog:''"></div>
                  </template>
                  <div>
                    <div style="display:inline-block;" v-if="scope.row.image && (scope.row.taskStatus == 3 && scope.row.bussinessStatus != 2)" class="demo-image__preview">
                      <i class="iconfont icon-picture" style="color:red;font-size:16px;"  @click="open(scope.row, scope.$index)"></i>
                    </div>
                    <i v-if="!scope.row.image" :class="$batchSendIconCj(scope.row.taskStatus, scope.row.bussinessStatus)"></i>
                    <span style="padding-left: 0 ;">{{$batchSendCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</span> 
                    <i class="iconfont icon-wenhao" v-if="(scope.row.errLog && scope.row.errLog != '成功')|| (scope.row.bussinessLog && scope.row.bussinessLog !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                  </div>
                </el-tooltip>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- 勾选认证 -->
      <el-table-column label="记录" min-width="140" v-else-if="this.taskName == 'tax-gxrz'">
        <template #default="scope">
          <div class="item_icon">
            <p>
              <i :class=" $batchSendIconSb(  scope.row.taskStatus, scope.row.bussinessStatus ) " ></i>
              <el-tooltip
                v-if="scope.row.bussinessLog"
                effect="dark"
                placement="top-start"
              >
                <template #content>
                  <div v-html=" scope.row.bussinessLog ? scope.row.bussinessLog : '' + scope.row.errLog ? scope.row.errLog : '' " ></div>
                </template>
                <span>  {{ $batchSendGxrz(  scope.row.taskStatus,  scope.row.bussinessStatus ) }}
                  <i class="iconfont icon-wenhao" style="color: #f56c6c"></i>
                </span>
              </el-tooltip>
              <span v-else>
                <span>{{ $batchSendGxrz( scope.row.taskStatus, scope.row.bussinessStatus ) }}</span>
              </span>
            </p>
          </div>
        </template>
      </el-table-column>

      <!-- 账簿内申报 -->
     
      <!-- 申报的记录 -->
      <el-table-column align="left" prop="districtName" label="记录" min-width="170" 
      v-else-if="this.taskName == 'tax-sb' || this.taskName == 'tax-sb-smallVat' || this.taskName == 'tax-sb-vat'|| this.taskName == 'tax-sb-taxQuarter' || this.taskName == 'tax-sb-deed' || this.taskName == 'tax-sb-qtsr' || this.taskName == 'tax-sb-sl' || this.taskName == 'tax-sb-whsyjsf' || this.taskName == 'tax-sb-cbj' || this.taskName == 'tax-sb-xfs' || this.taskName == 'tax-sb-ljcl' || this.taskName == 'tax-sb-shebao' || this.taskName == 'tax-sb-zcfz' || this.taskName == 'tax-sb-lrb' || this.taskName == 'tax-sb-xjllb' || this.taskName == 'tax-sb-cwbb' || this.taskName == 'tax-year-sb' || this.taskName == 'tax-cwyear-sb' || this.taskName == 'year-cw-qs' || this.taskName == 'year-tax-qs' "
      >
        <template #default="scope">
          <div class="item_icon">
            <p>
              <i :class="$batchSendIconSb(scope.row.taskStatus, scope.row.bussinessStatus)"></i>
              <el-tooltip class="item" effect="dark" :disabled="!scope.row.errLog && !scope.row.bussinessLog" placement="top-start">
                <template #content>
                  <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog?scope.row.errLog:''"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$batchSendWordSb(scope.row.taskStatus, scope.row.bussinessStatus)">{{$batchSendSb(scope.row.taskStatus,scope.row.bussinessStatus)}}</span>
                  <i class="iconfont icon-wenhao" v-if="(scope.row.bussinessStatus == 3 && scope.row.bussinessLog) || (scope.row.taskStatus == 4 && scope.row.errLog)" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
              <span v-if="scope.row.image && scope.row.taskStatus == 3" class="demo-image__preview" style="margin-left: 4px;">
                <i class="iconfont icon-picture" style="color:red;font-size:13px;" v-if="scope.row.bussinessStatus == 3" @click="open(scope.row,scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;" v-if="scope.row.bussinessStatus == 2 ||scope.row.bussinessStatus == 6 ||scope.row.bussinessStatus == 20 || scope.row.bussinessStatus == 22" @click="open(scope.row,scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#39b0d2;font-size:13px;" v-if="scope.row.bussinessStatus == 4 || scope.row.bussinessStatus == 5 || scope.row.bussinessStatus == 21" @click="open(scope.row,scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#e6a23c;font-size:13px;" v-if="scope.row.bussinessStatus == 23" @click="open(scope.row,scope.$index)"></i>

              </span>
            </p>
          </div>
        </template>
      </el-table-column>

      <!-- 扣款的记录 -->
      <el-table-column align="left" prop="districtName" label="记录" min-width="230" v-else-if="this.taskName == 'tax-kk-vat' || this.taskName == 'tax-kk-smallVat' || this.taskName == 'tax-kk-deed' || this.taskName == 'tax-kk-taxQuarter' || this.taskName == 'tax-kk-qtsr' || this.taskName == 'tax-kk-sl' || this.taskName == 'tax-kk-whsyjsf' || this.taskName == 'tax-kk-cbj' || this.taskName == 'tax-kk-xfs' || this.taskName == 'tax-kk-ljcl' || this.taskName == 'tax-kk-fjs' || this.taskName == 'tax-kk-cwbb' || this.taskName == 'tax-kk-shebao'">
        <template #default="scope">
          <div class="item_icon">
            <p>
              <span v-if="scope.row.image" class="demo-image__preview">
                <i class="iconfont icon-picture" style="color:red;font-size:13px;" v-if="scope.row.bussinessStatus == 3" @click="open(scope.row,scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;" v-if="scope.row.bussinessStatus == 2 ||scope.row.bussinessStatus == 13" @click="open(scope.row,scope.$index)"></i>
              </span>
              <i v-else :class="$batchSendIconSb(scope.row.taskStatus, scope.row.bussinessStatus)"></i>
              <el-tooltip class="item" effect="dark" :disabled="!scope.row.bussinessLog" placement="top-start">
                <template #content>
                  <div v-html="scope.row.bussinessLog"></div>
                </template>
                <span :class="$batchSendWordKk(scope.row.taskStatus,scope.row.bussinessStatus)">{{$batchSendKk(scope.row.taskStatus,scope.row.bussinessStatus)}}  <i class="iconfont icon-wenhao" style="color:red" v-if="scope.row.bussinessLog">
                </i></span>
              </el-tooltip>
              {{scope.row.endAt}}
              <span style="color: #67c23a;">金额：</span>
              <span v-if="scope.row.amountPaid">{{scope.row.amountPaid}}</span>
            </p>
          </div>
        </template>
      </el-table-column>

      <!-- 检查的记录 -->
      <el-table-column prop="districtName" label="记录" min-width="180" v-else-if="this.taskName == 'tax-jc-shenbao'">
        <template #default="scope">
          <div class="div_p" v-if="scope.row.taskStatus != '0' && scope.row.taskStatus != '' && scope.row.taskStatus != '1' && scope.row.taskStatus != '2'">
            <div class="item_icon">
              <p>
                <span v-if="scope.row.image" class="demo-image__preview" style="margin-left: 4px;">
                  <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="(scope.row.bussinessStatus == 3) || (scope.row.bussinessStatus == 30)" @click="open(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="(scope.row.bussinessStatus == 2)" @click="open(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-if=" (scope.row.bussinessStatus == 50)" @click="open(scope.row, scope.$index)"></i>
                </span>
                <i v-if="!scope.row.image" :class="$setUpStatusIconJc(scope.row.bussinessStatus, 'business')"></i>
                <el-tooltip :disabled="scope.row.bussinessLog == ''" class="item" effect="dark" :content="scope.row.bussinessLog?scope.row.bussinessLog:''" placement="top-start">
                  <span size="small" style="border: 0;" :class="$setUpStatusGsWordZf('5',scope.row.bussinessStatus)">{{$setUpStatusJc(scope.row.bussinessStatus, 'business')}}</span>
                </el-tooltip>
              </p>
              
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 完税证明检查的记录 -->
      <el-table-column prop="districtName" label="记录" min-width="180" v-else-if="this.taskName == 'tax-cj-wszm'">
        <template #default="scope">
          <div class="div_p" v-if="scope.row.taskStatus != '0' && scope.row.taskStatus != '' && scope.row.taskStatus != '1' && scope.row.taskStatus != '2'">
            <div class="item_icon">
              <p>
                <span v-if="scope.row.image" class="demo-image__preview" style="margin-left: 4px;">
                  <i class="iconfont icon-pdf" style="color:red;font-size:16px;" v-if="(scope.row.bussinessStatus == 3)" @click="openPdf(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-pdf" style="color:#67c23a;font-size:16px;" v-if="(scope.row.bussinessStatus == 2)" @click="openPdf(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-pdf" style="color:#e6a23c;font-size:16px;" v-if="(scope.row.bussinessStatus == 30) || (scope.row.bussinessStatus == 50)" @click="openPdf(scope.row, scope.$index)"></i>
                </span>
                <i v-if="!scope.row.image" :class="$setUpStatusIconJc(scope.row.bussinessStatus, 'business')"></i>
                <el-tooltip :disabled="scope.row.bussinessLog == ''" class="item" effect="dark" :content="scope.row.bussinessLog?scope.row.bussinessLog:''" placement="top-start">
                  <span size="small" style="border: 0;" :class="$setUpStatusGsWordZf('5',scope.row.bussinessStatus)">{{$setUpStatusWsJc(scope.row.bussinessStatus, 'business')}}</span>
                </el-tooltip>
              </p>
              
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 个税完税证明检查的记录 -->
      <el-table-column prop="districtName" label="记录" min-width="180" v-else-if="this.taskName == 'gs-cj-wszm'">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p>
                <span v-if="(scope.row.image) && [3,4].includes(scope.row.taskStatus)">
                  <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="scope.row.taskStatus == 4" @click="open(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-pdf" style="color:#67c23a;font-size:16px;" v-if="scope.row.taskStatus == 3" @click="openGsPdf(scope.row)"></i>
                </span>
                <i :class="$setUpStatusIconJc(scope.row.taskStatus, 'task')" v-else></i>
                <el-tooltip :disabled="scope.row.errLog == ''" class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.errLog?scope.row.errLog:''"></div>
                  </template>
                  <p>{{$setUpStatusWsJc(scope.row.taskStatus, "task")}}<i class="iconfont icon-wenhao" v-if="scope.row.taskStatus == 4 && scope.row.errLog" style="color:#f56c6c;margin:0 0 0 4px;"></i></p>
                </el-tooltip>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 个税申报的记录 -->
      <el-table-column align="left" prop="districtName" label="记录" min-width="180" v-else-if="this.taskName == 'gs-sb-gs' || this.taskName == 'gs-sb-gd' || this.taskName == 'gs-sb'">
        <template #default="scope">
          <div>
            <div class="item_icon">
              <p>
                <i  :class="$setUpStatusGsIconSb(scope.row.taskStatus,scope.row.bussinessStatus)"></i>
                <el-tooltip v-if="scope.row.bussinessLog" class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog?scope.row.errLog:''"></div>
                  </template>
                  <span :class="$setUpStatusGsWordSb(scope.row.taskStatus,scope.row.bussinessStatus)" @click="scope.row.image?open(scope.row,scope.$index):''">{{$setUpStatusGsSb(scope.row.taskStatus,scope.row.bussinessStatus)}}</span>
                  <!-- <div style="display: inline-block;"><i class="iconfont icon-wenhao" v-if="scope.row.bussinessStatus == 6" style="color:#f56c6c;margin:0 0 0 4px;"></i></div> -->
                </el-tooltip>
                <span v-else :class="$setUpStatusGsWordSb(scope.row.taskStatus,scope.row.bussinessStatus)" @click="scope.row.image?open(scope.row,scope.$index):''">
                  {{$setUpStatusGsSb(scope.row.taskStatus,scope.row.bussinessStatus)}}
                </span>
              </p>
              <span v-if="scope.row.image" class="demo-image__preview" style="margin-left: 4px;">
                <i class="iconfont icon-picture" style="color:red;font-size:13px;margin-right: 4px;" v-if="scope.row.bussinessStatus == 3 ||scope.row.bussinessStatus == 4 ||scope.row.bussinessStatus == 5 || scope.row.bussinessStatus == 6|| scope.row.bussinessStatus == 12" @click="open(scope.row,scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#e6a23c;font-size:13px;margin-right: 4px" v-if=" scope.row.bussinessStatus == 8 " @click="open(scope.row,scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;margin-right: 4px" v-if="scope.row.bussinessStatus == 10 ||scope.row.bussinessStatus == 14 ||scope.row.bussinessStatus == 13 || scope.row.bussinessStatus == 20 " @click="open(scope.row,scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#39b0d2;font-size:13px;margin-right: 4px" v-if="(scope.row.bussinessStatus == 11 || scope.row.bussinessStatus == 2 || scope.row.bussinessStatus == 7)" @click="open(scope.row,scope.$index)"></i>
              </span>
              <el-tooltip :disabled="(scope.row.errLog?scope.row.errLog:'' + scope.row.bussinessLog?scope.row.bussinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                <template #content>
                  <div v-html="scope.row.errLog?scope.row.errLog:'' + scope.row.bussinessLog?scope.row.bussinessLog:''"></div>
                </template>
                <div style="display:inline-block">
                  <i class="iconfont icon-wenhao" v-if="(scope.row.errLog && scope.row.errLog != '成功')|| (scope.row.bussinessLog && scope.row.bussinessLog !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                </div>
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 个税扣款的记录 -->
      <el-table-column align="left" prop="districtName" label="记录" min-width="180" v-else-if="this.taskName == 'gs-kk-gs' || this.taskName == 'gs-kk-gd'">
        <template #default="scope">
          <div class="div_p">
              <div>
                <span v-if="scope.row.image" class="demo-image__preview" >
                  <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;cursor: pointer;" @click="open(scope.row,scope.$index)"></i>
                </span>
                <div class="item_icon">
                  <p>
                    <i v-if="!scope.row.image"  :class="$setUpStatusGsIconSb(scope.row.taskStatus,scope.row.bussinessStatus)"></i>
                    <el-tooltip v-if="scope.row.bussinessLog" class="item" effect="dark" placement="top-start">
                      <template #content>
                        <div v-html="scope.row.businessLog?scope.row.businessLog:'' + scope.row.errLog?scope.row.errLog:''"></div>
                      </template>
                      <span :class="$setUpStatusGsWordSb(scope.row.taskStatus, scope.row.bussinessStatus)" @click="scope.row.image?open(scope.row,scope.$index):''">{{$setUpStatusGsKk(scope.row.taskStatus, scope.row.bussinessStatus)}}</span>
                    </el-tooltip>
                    <span v-else>
                      <span :class="$setUpStatusGsWordSb(scope.row.taskStatus, scope.row.bussinessStatus)" @click="scope.row.image?open(scope.row,scope.$index):''">{{$setUpStatusGsKk(scope.row.taskStatus, scope.row.bussinessStatus)}}</span>
                    </span>
                  </p>
                  <el-tooltip :disabled="(scope.row.errLog?scope.row.errLog:'' + scope.row.bussinessLog?scope.row.bussinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="scope.row.errLog?scope.row.errLog:'' + scope.row.bussinessLog?scope.row.bussinessLog:''"></div>
                    </template>
                    <div style="display:inline-block">
                      <i class="iconfont icon-wenhao" v-if="(scope.row.errLog && scope.row.errLog != '成功')|| (scope.row.bussinessLog && scope.row.bussinessLog !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
        </template>
      </el-table-column>
      <!-- 历史数据采集记录 -->
      <el-table-column align="center" prop="districtName" label="记录" min-width="180" v-else-if="this.taskName == 'his-cj-historyCollect'">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p>
                <i :class="$setUpStatusJzCjIcon(scope.row.taskStatus,scope.row.bussinessStatus)"></i>
                <el-tooltip v-if="scope.row.bussinessLog || scope.row.errLog" class="scope.row" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog?scope.row.errLog:''">
                    </div>
                  </template>
                  <span>{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}<i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.bussinessLog || scope.row.errLog">
                  </i></span>
                </el-tooltip>
                <span v-else>
                  <span>{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</span>
                </span>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 历史数据导入 -->
      <el-table-column align="center" prop="districtName" label="记录" min-width="180" v-else-if="this.taskName == 'his-cj-historyImport'">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p v-if="scope.row.taskStatus == '0'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusImport(scope.row.taskStatus)}}
                </p>
                <p v-if="scope.row.taskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusImport(scope.row.taskStatus)}}
                </p>
                <p v-if="scope.row.taskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusImport(scope.row.taskStatus)}}</p>
                <p v-if="scope.row.taskStatus == '3'"><i class="iconfont icon-duihao"></i>
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="scope.row.businessLog?scope.row.businessLog:'' + scope.row.errLog?scope.row.errLog:''">
                      </div>
                    </template>
                    <span>{{$setUpStatusImport(scope.row.taskStatus)}} <i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.bussinessLog || scope.row.errLog">
                    </i> </span>
                  </el-tooltip>
                </p>
                <p v-if="scope.row.taskStatus == '4'"><i class="iconfont icon-cuowu"></i>
                  <el-tooltip class="item" effect="dark"  placement="top-start">
                    <template #content>
                      <div v-html="scope.row.businessLog?scope.row.businessLog:'' + scope.row.errLog?scope.row.errLog:''">
                      </div>
                    </template>
                    <span>{{$setUpStatusImport(scope.row.taskStatus)}}<i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.bussinessLog || scope.row.errLog">
                   </i></span>
                  </el-tooltip>
                </p>
                <p v-if="scope.row.taskStatus == '5'"><i class="iconfont icon-cuowu"></i>{{$setUpStatusImport(scope.row.taskStatus)}}</p>
              </div>
            </div>
          </template>
        </el-table-column>
      <!--  历史数据匹配记录 -->
      <el-table-column align="center" prop="districtName" label="记录" min-width="200" v-else-if="this.taskName == 'his-cj-historyMatch'">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p v-if="scope.row.taskStatus == '0'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusMatch(scope.row.taskStatus)}}
              </p>
              <p v-if="scope.row.taskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusMatch(scope.row.taskStatus)}}
              </p>
              <p v-if="scope.row.taskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusMatch(scope.row.taskStatus)}}</p>
              <p v-if="scope.row.taskStatus == '3'"><i class="iconfont icon-duihao"></i>
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog?scope.row.errLog:''"></div>
                  </template>
                  <span>{{$setUpStatusMatch(scope.row.taskStatus)}}<i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.bussinessLog || scope.row.errLog">
                  </i></span>
                </el-tooltip>
              </p>
              <p v-if="scope.row.taskStatus == '4'"><i class="iconfont icon-cuowu"></i>
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog?scope.row.errLog:''"></div>
                  </template>
                  <span>{{$setUpStatusMatch(scope.row.taskStatus)}}<i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.bussinessLog || scope.row.errLog">
                  </i></span>
                </el-tooltip>
              </p>
              <p v-if="scope.row.taskStatus == '5'"><i class="iconfont icon-cuowu"></i>{{$setUpStatusMatch(scope.row.taskStatus)}}</p>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="districtName" label="记录" min-width="250" v-else>
        <template #default="scope">
            <span v-if="scope.row.errLog + scope.row.bussinessLog" size="small" class="sbyc2" style="border: 0;white-space: break-spaces;"> {{scope.row.errLog}} <br> {{scope.row.bussinessLog}} </span>
            <span v-else-if="scope.row.endAt && scope.row.taskStatus == 3 && scope.row.bussinessStatus == 2" class="sbcg2">成功</span>
        </template>
      </el-table-column>

    </el-table>
    <div class="dialog-footer">
      <el-button type="primary" @click="dialogFormVisible = false" size="small">关闭</el-button>
    </div>
  </el-dialog>

  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
  <el-dialog v-model="dialogVisible" title="查看详情" width="800px" destroy-on-close :close-on-click-modal="false">
    <el-table :data="checkList" style="width: 100%" border :height="contentStyleObj">
      <el-table-column align="left" label="税种" min-width="130" prop="name"></el-table-column>
      <el-table-column align="left" label="税额" min-width="130" prop="amount"></el-table-column>
      <el-table-column align="center" label="操作" min-width="180">
          <template #default="scope">
            <el-button type="primary" @click="checkUrl(scope.row)" size="small" plain icon="View">查看</el-button>
          </template>
        </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="small">关 闭</el-button>
      </span>
    </template>
  </el-dialog>
  <sbImage ref="sbImage" />
</template>

<script>
import { taxTaskList } from "@/api/taxCalculation"
import sbImgUrl from "./sbImgUrl"
import sbImage from '@/components/sbImage'
import { adaptiveSize2A5PDF } from "@/api/printSet";

export default {
  name:'declareDetails',
  components:{
    sbImgUrl,
    sbImage
  },
  props: {
    changeTaxKind:{
      default:true,
      type:Boolean
    }
  },
  data() {
    return{
      dialogFormVisible:false,
      tableData: [],
      contentStyleObj:{}, //高度变化
      taskName: '',
      checkList:[],
      dialogVisible:false,
    } 
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(350)
  },
  methods: {
    //弹窗
    init(comId, period, taskName,source){
      if(!comId || !period){
        this.$qzfMessage("信息错误", 1)
        return
      }
      if(!taskName) {
        this.$qzfMessage("无记录", 1)
        return
      }
      
      taxTaskList({taskName, comId, period,source}).then(res=>{
        if(res.data.data.list && res.data.data.list.length != 0){
          this.tableData = res.data.data.list
          this.taskName = taskName
          this.dialogFormVisible = true
        }else{
          this.$qzfMessage("无记录", 1)
          return
        }
      })
    },
    open(row, index) {
      let imageAll = []
      let arr = []
      let currentIndex = index
      this.tableData.map((res,i)=>{
        if(res.image.match(',')){
          let imgList = []
          let imgUrls = res.image.split(',')
          imgList = imgUrls.filter(v=>{return v})
          imgList.map((item,i2)=>{
            if(item){
              if(res.tableName == 'gs_gs' && res.bussinessStatus == '5' && res.amount2.match('.xls')){
                arr.push({
                  img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                  tableName:'',
                  ycExcel:res.amount2
                })
              }else{
                arr.push({
                  img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                  tableName:''
                })
              }
              if(i< index && i2>0){
                currentIndex ++
              }
            }
          })
        }else{
          if(res.image){
            if(res.tableName == 'gs_gs' && res.bussinessStatus == '5' && res.amount2.match('.xls')){
              arr.push({
                img:'https://file.listensoft.net' + res.image + "?" + Math.random(10),
                tableName:'',
                ycExcel:res.amount2
              })
            }else{
              arr.push({
                img:'https://file.listensoft.net' + res.image + "?" + Math.random(10),
                tableName:''
              })
            }
          }else{
            if(i<index){
              currentIndex --
            }
          }
        }
      })
      let url = {
        name:'记录',
        url:arr,
      }
      imageAll.push(url)
      this.$refs.sbImage.dialog(imageAll, 0,currentIndex)
    },
    openPdf(row){
      if(row.image.match(',')){
        let arr = row.image.split(',')
        let checkArr = []
        arr.map(v=>{
          let textArr = v.split('-')
          checkArr.push({
            name: textArr[0],
            amount:textArr[1],
            url:  textArr[2]
          })
        })
        this.checkList = checkArr
        this.dialogVisible = true
      }else{
        let textArr = row.image.split('-')
        window.open('https://file.listensoft.net/' + textArr[2])
      }
    },
    checkUrl(row){
      window.open('https://file.listensoft.net/' + row.url)
    },
    openGsPdf(row){
      let param = {
        markType: '个税完税证明',
        imgPath: row.image,
      };
      adaptiveSize2A5PDF(param).then((res) => {
        if (res.data.msg == "success") {
          window.open("https://file.listensoft.net" + res.data.data.path);
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: right;
  margin-top: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 4px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
    margin-left: 4px;
  }
}
</style>